import React from 'react';
const _ = {
  shuffle: require('lodash.shuffle')
}

 export default class Component extends React.Component {
   state = {row1: [], row2: []}

   componentDidMount() {
     var row1 = (this.props.tiles && _.shuffle(this.props.tiles).slice(0,12)) || [];
     var row2 = row1.slice(6,12);
     row1 = row1.slice(0,6);
     this.setState({row1, row2})
   }


   render() {
       
   return (
     <div>
       {this.state.row1.map((tile) => {
         return (
           <div className="col-md-2 col-sm-2 col-xs-6" style={{padding: 0}}>
             <a href={`https://www.instagram.com/p/${tile.filename.substring(0, tile.filename.indexOf(".jpg"))}`} target="_blank" >
               <div style={{paddingTop: '100%', backgroundImage: 'url(' + tile.url + ')', backgroundSize: 'cover'}}></div>
             </a>
             <i className="social-key fa fa-lg fa-instagram"
             style={{fontSize: '2em', position: 'absolute', bottom: '10px', right: '10px', color: 'rgba(255, 255, 255, 0.4)', transition: '0.2s ease-out'}}></i>
           </div>
         )
       })}
       {this.state.row2.map((tile) => {
         return (
           <div className="col-md-2 col-sm-2 col-xs-6 hidden-xs hidden-sm" style={{padding: 0}}>
             <a href={`https://www.instagram.com/p/${tile.filename.substring(0, tile.filename.indexOf(".jpg"))}`} target="_blank" >
               <div style={{paddingTop: '100%', backgroundImage: 'url(' + tile.url + ')', backgroundSize: 'cover'}}></div>
             </a>
             <i className="social-key fa fa-lg fa-instagram" style={{fontSize: '2em', position: 'absolute', bottom: '10px', right: '10px', color: 'rgba(255, 255, 255, 0.4)', transition: '0.2s ease-out'}}></i>
           </div>
         )
       })}
       <div id="instafeed" style={{display: 'none'}}></div>
     </div>
   )
  }
}
