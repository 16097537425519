import React from 'react';
import Layout from '../layouts';
import HeroImage from '../images/hero.jpg';
import Tiles from 'pourmgmt/tiles';
import Signup from 'pourmgmt/signup';
import {Row, Col} from 'react-bootstrap';

export default class Component extends React.Component {
  render() {
    return (
    <Layout padding="0px" >
    <div style={{minHeight: '400px'}}>
      <Row>
      <div style={{background: `url(${HeroImage}) center center / cover no-repeat`, height: '300px'}}></div>
      </Row>
      <Row style={{padding: '0px 10px'}}>
      <Col md={8} mdOffset={2}>
        <p className="text-1" style={{textAlign: 'center', marginTop: '60px', marginBottom: '40px', color: '#000', fontSize: '30px', lineHeight: '32px', fontWeight: 'bold', textTransform: 'uppercase'}}>Standing on the shoulders of giants</p>
      </Col>
      </Row>
      <Row style={{padding: '0px 10px 30px 10px'}}>
        <Col md={6} mdOffset={3}>
          <div>
            <Signup source="www.iconicwine.com" list="c13112cdfd" notify />
          </div>
        </Col>
      </Row>
      <Row>
        <Tiles tiles={this.props.pageContext.tiles} />
      </Row>
    </div>
    </Layout>
    )
  }
}
